import React, { useRef } from 'react';
export default function BringResults(props) {
  let toogleRef = useRef([]);
  const indexes = [0, 1];
  const toogleFun = (ind, play = true) => {
    indexes?.forEach(i => {
      if (i != ind) document.getElementById('play_' + i).style.display = 'block';

      document.getElementById('pause_' + i).classList.remove('paused');
      toogleRef.current[i]?.pause();
    });
    if (play) {
      toogleRef.current[ind]?.play();
      document.getElementById('play_' + ind).style.display = 'none';

      document.getElementById('pause_' + ind).classList.add('paused');
    } else {
      toogleRef.current[ind]?.pause();

      document.getElementById('play_' + ind).style.display = 'block';
      document.getElementById('pause_' + ind).classList.remove('paused');
    }
  };
  const settings = {
    infinite: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    arrows: false,
    dots: false,
    swipe: true,
    autoplay: false,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          arrows: false,
          swipe: false,
        },
      },
    ],
  };

  const handleScroll = e => {
    if (
      document.getElementById('slider_reviews').offsetTop + document.getElementById('slider_reviews').clientHeight <= window.pageYOffset ||
      document.getElementById('slider_reviews').offsetTop - document.getElementById('slider_reviews').clientHeight >= window.pageYOffset
    ) {
      indexes?.forEach((el, i) => {
        // document.getElementById('play_' + i).style.display="block"
        // document.getElementById('pause_' + i).classList.remove("paused")
        toogleRef.current[i]?.pause();
      });
    }
  };
  const tooglePlayPause = (ind, play) => {
    // if(window.innerWidth < 1024) toogleFun(ind,play)
  };
  return (
    <>
      <div className="block  relative" id={'slider_reviews'}>
        <Slider {...settings} className="relative z-10">
          <div>
            <div className={` w-full pr-0 md:pr-10   mx-10`}>
              <div className="relative">
                <video
                  className=" max-w-400 md:max-w-700"
                  controls={true}
                  onChange={() => tooglePlayPause(1, toogleRef.current[1].paused)}
                  poster={'https://admin.weprodigi.com/uploads/images/home-page/posterNare.jpg'}
                  webkitplaysinline={'true'}
                  playsInline
                  width={'100%'}
                  ref={element => toogleRef.current.push(element)}
                >
                  <source src="https://weprodigi.com/uploads/images/home-page/PPCAdvertisingCaseStudy.mp4" type="video/mp4" />
                </video>
              </div>
              <div>
                <h2 className="text-purple leading-20 mt-5 font-bolder text-16">Arara Tour - PPC Case</h2>
                <p className="text-black leading-20 text-16 mt-10">
                  We helped Arara Tour achieve remarkable results, including a 1,745% increase in conversion volume, 42% decrease in cost per
                  conversion, and 480 leads.
                </p>
              </div>
            </div>
          </div>

          <div>
            <div className={` hidden md:block  w-full pl-0 md:pl-10 mx-10`}>
              <div className="relative">
                <video
                  className=" max-w-400 md:max-w-700"
                  controls={true}
                  onChange={() => tooglePlayPause(0, toogleRef.current[0].paused)}
                  poster={'https://admin.weprodigi.com/uploads/images/home-page/poster.jpeg'}
                  webkitplaysinline={'true'}
                  playsInline
                  width={'100%'}
                  ref={element => toogleRef.current.push(element)}
                >
                  <source src="https://weprodigi.com/uploads/images/home-page/VohcoLingerieSMMCase.mp4" type="video/mp4" />
                </video>
              </div>
              <div>
                <h2 className="text-purple leading-10 font-bolder mt-5  text-16">Vohco - Ecommerce Case</h2>
                <p className="text-black leading-20 text-16">
                  We have increased the sales from 0 to $9000 only in a month. The number of orders from 0 to 43.
                </p>
              </div>
            </div>
          </div>
        </Slider>
      </div>
    </>
  );
}
import Slider from 'react-slick';

import 'slick-carousel/slick/slick.css';
